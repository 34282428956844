import ActionsContainer from '@/components/ActionsContainer/ActionsContainer';
import { CenteredLoadingSpinner } from '@/components/CenteredLoadingSpinner/CenteredLoadingSpinner';
import ContentContainer from '@/components/ContentContainer/ContentContainer';
import Layout from '@/components/Layout/Layout';
import Link from '@/components/Link';
import { Text } from '@soluto-private/mx-asurion-ui-react';
import { signIn, useSession } from 'next-auth/react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import confusedWoman from 'public/confusedWoman.svg';
import { useEffect } from 'react';
import styles from './SignIn.module.css';

export default function SignIn() {
  const { status, data: session } = useSession();
  const router = useRouter();

  useEffect(() => {
    if (
      status === 'unauthenticated' ||
      (session?.expires && new Date(session.expires) < new Date())
    ) {
      signIn('asurion', {
        callbackUrl: (router.query.callbackUrl as string) ?? '/',
      });
    }
    if (status === 'authenticated') {
      router.replace('/');
    }
  }, [router, status, session]);

  /**
   * If there are any errors in sigining in, display the error sigining in page
   * with sign in button
   */
  if (router.query.error != null) {
    return (
      <Layout>
        <ContentContainer>
          <div className={styles['sign-in-error']}>
            <Image
              src={confusedWoman}
              alt="illustration of a woman with question marks circling her head"
              width={200}
              height={200}
            />
            <Text size={5} weight="feather">
              There was an error signing in. Please try again.
            </Text>
          </div>
        </ContentContainer>
        <ActionsContainer>
          <Link
            href="/sign-in"
            shallow={true}
            variant="button"
            btnVariantProps={{
              btnSize: 'medium',
              btnVariant: 'default',
            }}
          >
            Sign in
          </Link>
        </ActionsContainer>
      </Layout>
    );
  }

  return <CenteredLoadingSpinner title="Sign-In Page Loading Spinner" />;
}
